import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import EventLandingPage from "./demos/EventLandingPage"
import WikiTemplate from "./demos/WikiTemplate"

import ThankYouPage from "ThankYouPage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          {/* <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} /> */}
          {/* <Route path="/" element={<WikiTemplate />}/> */}
          <Route path="/" element={<EventLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}