import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import { MDBContainer } from "mdb-react-ui-kit";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}}
  ${NavToggle}.closed { ${tw`text-gray-100 hover:text-primary-500`}}`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`} background-image: url("https://staticg.sportskeeda.com/editor/2022/04/ab2a0-16492353149125-1920.jpg");`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;
const Heading = styled.h1`${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`} span { ${tw`inline-block mt-2`}}`;
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const blogPosts = [
    {
      imageSrc: "https://www.freeiconspng.com/uploads/wonderful-pdf-icon-logo-6.png",
      title: "in-die Valley Yatırımcı Formu",
      url: "https://forms.gle/Ei5kaUbhd8mxd4K96"
    },
    {
      imageSrc: "https://cdn-icons-png.flaticon.com/512/1484/1484799.png",
      title: "in-die Valley Indie Bilgilendirme Formu",
      url: "https://forms.gle/1UxKCuYvY29brzZDA"
    },
    {
      imageSrc: "https://icon2.cleanpng.com/20240415/tau/transparent-colorful-background-palette-with-blue-yellow-and-green-colors661cf193af9319.98656930.webp",
      title: "in-die Portreleri Boyama Başvurusu",
      url: "https://forms.gle/z7Bst6vh5AGTZmt86"
    },
    {
      imageSrc: "https://w7.pngwing.com/pngs/130/566/png-transparent-valuedesk-gmbh-proposal-apache-cordova-service-computer-software-grafik.png",
      title: "in-die Valley Genel Öneri Formu",
      url: "https://forms.gle/Wdg6eGpbEZnRmiET9"
    }
  ];

  const UserCard = ({ name, image, url }) => {
    return (
      <div>
        <MDBContainer className="my-5 d-flex flex-column justify-content-center align-items-center">
          <img
            src={image}
            className="rounded-circle mb-3"
            style={{ width: "64px" }}
          />
          <NavLink href={url} target="_blank">{name}</NavLink>
        </MDBContainer>
      </div>
    );
  }

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Hakkımızda
      </NavLink>
      <NavLink href="/">
        Blog
      </NavLink>
      <NavLink href="https://tr-wiki.metin2.gameforge.com/index.php/Anasayfa">
        Wiki
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="https://discord.gg/Vggvs6f2">
        Discord
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading style={{marginTop: "4rem"}}>
            Hepimiz bir vadideyiz, in-die Valley
            <br />
            Pixel Tablosundaki Yerini Al.
          </Heading>
          <PrimaryAction onClick={() => window.open("https://forms.gle/z7Bst6vh5AGTZmt86", "_blank")}>Bizimle İletişime Geçin</PrimaryAction>
          <div style={{
            display: "flex",
            marginTop: "32px",
            justifyContent: "center",
            display: "flex",
            marginTop: "32px",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            color: "white"
          }}>
            {blogPosts.map(blog => (
              <UserCard name={blog.title} image={blog.imageSrc} url={blog.url}>{blog.title}</UserCard>
            ))}
          </div>
        </Content>
      </HeroContainer>
    </Container>
  );
};
